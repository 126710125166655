import React from 'react'

const header = props => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-dark">
        <a className="navbar-brand" href="/">
          <img src="/logo32.png" alt="Text Case Convert" className="logo" />Text Case Convert</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse " id="navbarCollapse">
          <ul className="navbar-nav ml-auto">
            {/* <li className="nav-item">
              <a className="nav-link" href="/timer" >Timer</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/chronometer" >Chronometer</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/pomodoro-timer" >Pomodoro Timer</a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" href="/calculator" >Calculator</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/qr-code-generator" >QR Code Generator</a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Pretty Print Tools
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="/json-pretty-print" >JSON Pretty Print</a>
                <a className="dropdown-item" href="/xml-pretty-print" >XML Pretty Print</a>
              </div>
            </li>            
            <li className="nav-item">
              <a className="nav-link" href="/character-count" >Character Count</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/tally-counter" >Tally Counter</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/morse-code" >Morse Code Translator</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/replace-text" >Text Replacer</a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                UUID Tools
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="/uuid-generator" >UUID Generator</a>
                <a className="dropdown-item" href="/uuid-formatter" >UUID Formatter</a>
                <a className="dropdown-item" href="/uuid-validator" >UUID Validator</a>
              </div>
            </li>            
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Random Generators
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="/wheel-of-names" >Wheel of Names</a>
                <a className="dropdown-item" href="/random-name" >Random Name Generator</a>
                <a className="dropdown-item" href="/random-number" >Random Number Generator</a>
                <a className="dropdown-item" href="/password-generator" >Password Generator</a>
              </div>
            </li>            
            <li className="nav-item">
              <a className="nav-link" href="/blog" >Blog</a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default header