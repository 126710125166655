import React from 'react'
// import { Switch, Route, Redirect } from 'react-router'
import { Switch, Route } from 'react-router'
import loadable from '@loadable/component'

import { LANGUAGES } from '../_i18n/languages'
// const Terms = loadable(() => import('../terms/Terms'))


const Home = loadable(() => import('../home/Home'))
const About = loadable(() => import('../about/About'))
const Terms = loadable(() => import('../terms/Terms'))
const Privacy = loadable(() => import('../privacy/Privacy'))
const NotFound = loadable(() => import('../notFound/NotFound'))
const JsonPretty = loadable(() => import('../jsonPretty/JsonPretty'))
const XmlPretty = loadable(() => import('../xmlPretty/XmlPretty'))
// const Chronometer = loadable(() => import('../chronometer/Chronometer'))
// const Timer = loadable(() => import('../timer/Timer'))
const CharacterCount = loadable(() => import('../counters/CharacterCount'))
const UuidGenerator = loadable(() => import('../uuid/UuidGenerator'))
const UuidFormatter = loadable(() => import('../uuid/UuidFormatter'))
const UuidValidator = loadable(() => import('../uuid/UuidValidator'))
const TextReplacer = loadable(() => import('../textReplacer/TextReplacer'))
// const PomodoroTimer = loadable(() => import('../pomodoroTimer/PomodoroTimer'))
const RandomName = loadable(() => import('../random/RandomName'))
const RandomNumber = loadable(() => import('../random/RandomNumber'))
const TallyCounter = loadable(() => import('../counters/TallyCounter'))
const MorseCodeTranslator = loadable(() => import('../morseCode/MorseCodeTranslator'))
const NamePickerWheel = loadable(() => import('../pickerWheel/NamePickerWheel'))
const Calculator = loadable(() => import('../calculator/Calculator'))
const QrCodeGenerator = loadable(() => import('../qrcode/QrCodeGenerator'))
const PasswordGenerator = loadable(() => import('../password/PasswordGenerator'))


const MultiLanguageRoute = (props) => {
    //const defaultLanguage = LANGUAGES.en.urlLang
    let hasLang = props.computedMatch.params.lang
    hasLang = hasLang ? hasLang : 'en'
    const is404Page = props.location.pathname.split('/')[3]
    //const isBasePathWithoutLang = props.location.pathname === "/"
    const currentLanguage = LANGUAGES[hasLang]

    //if(isBasePathWithoutLang)  return <Redirect to={`/${defaultLanguage}`} />
    //if(!hasLang && !is404Page) return <Redirect to={`/${defaultLanguage}`} />
    if (!currentLanguage || is404Page) return <Route component={NotFound} />

    return <Route {...props} />
}

const RedirectRoute = (props) => {
    //const defaultLanguage = LANGUAGES.en.urlLang
    let hasLang = props.computedMatch.params.lang
    hasLang = hasLang ? hasLang : 'en'
    const is404Page = props.location.pathname.split('/')[3]
    const path = props.location.pathname.split('/')[1]
    const switchPath = {
        'pomodoro-timer': '/pomodoro-timer',
        'timer': '/countdown-timer',
        'chronometer': ''
    }
    const currentLanguage = LANGUAGES[hasLang]
    if (!currentLanguage || is404Page) return <Route component={NotFound} />
    return (window.location = `https://online.stopwatch-timer.net${switchPath[path]}${hasLang && hasLang !== 'en' ? `/${hasLang}` : ''}`)
}

const routes = props => (
    <React.Fragment>
        <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/about' component={About} />
            <Route path='/terms' component={Terms} />
            <Route path='/privacy' component={Privacy} />
            <Route path='/json-pretty-print' component={JsonPretty} />
            <Route path='/xml-pretty-print' component={XmlPretty} />
            <Route path='/case-converter' render={(props) => <Home {...props} custom={{
                'title': 'Case Converter - TextCaseConvert',
                'description': 'Case Converter is a free online tool to converts any text to Title Case, UPPERCASE, lowercase, camelCase, PascalCase, aLtErNaTiNg, Sentence case and more'
            }} />} />
            <Route path='/convertcase' render={(props) => <Home {...props} custom={{
                'title': 'Convert Case - TextCaseConvert',
                'description': 'Convert Case is a free online tool to converts any text to Title Case, UPPERCASE, lowercase, camelCase, PascalCase, aLtErNaTiNg, Sentence case and more'
            }} />} />
            <Route path='/caps-convert' render={(props) => <Home {...props} custom={{
                'title': 'Caps Convert - TextCaseConvert',
                'description': 'Caps Convert is a free online tool to convert text to UPPERCASE. See more options of conversions for lowercase, camelCase, PascalCase, aLtErNaTiNg, Sentence case and more'
            }} />} />
            <Route path='/case-changer' render={(props) => <Home {...props} custom={{
                'title': 'Case Changer - TextCaseConvert',
                'description': 'Case Changer is a free online tool to change any text to Title Case, UPPERCASE, lowercase, camelCase, PascalCase, aLtErNaTiNg, Sentence case and more'
            }} />} />
            <Route path='/convert-text' render={(props) => <Home {...props} custom={{
                'title': 'Convert Text - TextCaseConvert',
                'description': 'Convert Text is a free online tool to convert any text to Title Case, UPPERCASE, lowercase, camelCase, PascalCase, aLtErNaTiNg, Sentence case and more'
            }} />} />
            <Route path='/uppercase-to-lowercase' render={(props) => <Home {...props} custom={{
                'title': 'UpperCase to LowerCase - TextCaseConvert',
                'description': 'UpperCase to LowerCase is a free online tool to convert text in uppercase to lowercase. See more options of conversions for Title Case, camelCase, PascalCase, aLtErNaTiNg, Sentence case and more'
            }} />} />
            <Route path='/convert-caps-to-lowercase' render={(props) => <Home {...props} custom={{
                'title': 'Convert Caps to LowerCase - TextCaseConvert',
                'description': 'Convert Caps to LowerCase is a free online tool to convert text in caps to lowercase. See more options of conversions for Title Case, camelCase, PascalCase, aLtErNaTiNg, Sentence case and more'
            }} />} />
            <Route path='/lowercase-into-uppercase' render={(props) => <Home {...props} custom={{
                'title': 'LowerCase into UpperCase - TextCaseConvert',
                'description': 'LowerCase into UpperCase is a free online tool to convert text in lowercase to uppercase. See more options of conversions for Title Case, camelCase, PascalCase, aLtErNaTiNg, Sentence case and more'
            }} />} />
            <Route path='/capitalize-text' render={(props) => <Home {...props} custom={{
                'title': 'Capitalize Text - TextCaseConvert',
                'description': 'Capitalize Text is a free online tool to convert any text to capitalized text. See more options of conversions for Title Case, camelCase, PascalCase, aLtErNaTiNg, Sentence case and more'
            }} />} />
            <Route path='/text-convert-uppercase' render={(props) => <Home {...props} custom={{
                'title': 'Text Convert Uppercase - TextCaseConvert',
                'description': 'Text Convert Uppercase is a free online tool to convert any text to uppercase text. See more options of conversions for Title Case, camelCase, PascalCase, aLtErNaTiNg, Sentence case and more'
            }} />} />
            <Route path='/kebab-case' render={(props) => <Home {...props} custom={{
                'title': 'Kebab Case Converter - TextCaseConvert',
                'description': 'Kebab Case Converter is a free online tool to convert any text to Kebab Case text. See more options of conversions for Title Case, camelCase, PascalCase, aLtErNaTiNg, Sentence case and more'
            }} />} />
            <MultiLanguageRoute path='/replace-text/:lang?' component={TextReplacer} />
            <MultiLanguageRoute path='/uuid-generator/:lang?' component={UuidGenerator} />
            <MultiLanguageRoute path='/uuid-formatter/:lang?' component={UuidFormatter} />
            <MultiLanguageRoute path='/uuid-validator/:lang?' component={UuidValidator} />
            <MultiLanguageRoute path='/character-count/:lang?' component={CharacterCount} />
            {/* <MultiLanguageRoute path='/pomodoro-timer/:lang?' component={PomodoroTimer} />
            <MultiLanguageRoute path='/chronometer/:lang?' component={Chronometer} />
            <MultiLanguageRoute path='/timer/:lang?' component={Timer} /> */}
            {/* <Route path="/pomodoro-timer/:lang?" render={() => (window.location = "https://online.stopwatch-timer.net/pomodoro-timer/"+props.computedMatch.params.lang)} /> */}
            <MultiLanguageRoute path='/random-name/:lang?' component={RandomName} />
            <MultiLanguageRoute path='/random-number/:lang?' component={RandomNumber} />
            <MultiLanguageRoute path='/tally-counter/:lang?' component={TallyCounter} />
            <MultiLanguageRoute path='/morse-code/:lang?' component={MorseCodeTranslator} />
            <MultiLanguageRoute path='/wheel-of-names/:lang?' component={NamePickerWheel} />
            <MultiLanguageRoute path='/calculator/:lang?' component={Calculator} />
            <MultiLanguageRoute path='/qr-code-generator/:lang?' component={QrCodeGenerator} />
            <MultiLanguageRoute path='/password-generator/:lang?' component={PasswordGenerator} />
            <RedirectRoute path="/pomodoro-timer/:lang?" />
            <RedirectRoute path='/chronometer/:lang?' />
            <RedirectRoute path='/timer/:lang?' />
            <MultiLanguageRoute path='/:lang' component={Home} />
            <Route component={NotFound} />
        </Switch>
    </React.Fragment>
)

export default routes